import { useState, useEffect } from "react";
import Calendar from "../../components/Calendar";
import { Image, Carousel } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOneMainBanner } from "../../http/activitiesAPI";
import ShareButton from "../../components/UI/ShareButton";
import parse from "html-react-parser";

const MainBannerInfoPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		fetchOneMainBanner(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, [id]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				{data ? (
					<div className='d-flex flex-column col-xl-8 px-xl-4'>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column justify-content-center'>
							{data.img ? (
								<Image
									className='rounded'
									style={{
										objectFit: "contain",
										maxHeight: "50vh",
									}}
									src={process.env.REACT_APP_API_URL + data.img}
								/>
							) : null}
							<span className='text-justify font-2 py-3 py-xl-2'>{parse(data.description)}</span>
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100'>
							<span className='border border-primary rounded text-primary my-1 p-2 fw-bold'>Autor: {data.author}</span>
							<span className='border border-primary rounded text-primary my-1 p-2 fw-bold'>{data.date}</span>
							<div className='d-flex flex-row align-items-center justify-content-end my-1'>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default MainBannerInfoPage;
