import React, { useContext, useState, useEffect } from "react";
import { Tabs, Tab, Pagination } from "react-bootstrap";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { Spinner } from "react-bootstrap";
import { fetchConsultations, fetchInitiations, fetchRecommendations } from "../http/informationProcessAPI";
import { INITIATION_ROUTE, PUBLIC_CONSULTATIONS_ROUTE, RECOMENDATIONS_ROUTE, DRAFT_DECISIONS_ROUTE } from "../utils/consts";
import { Link } from "react-router-dom";
import { fetchDraftDecisions } from "../http/navLinksAPI";
import { observer } from "mobx-react-lite";

const DecisionProcess = observer(() => {
	const { informationProcess } = useContext(Context);
	const [loading, setLoading] = useState(true);

	const consultationPages = [];
	const initiationPages = [];
	const recommendationsPages = [];
	const draftDecisionsPages = [];

	useEffect(() => {
		fetchConsultations(1, 5)
			.then((data) => {
				informationProcess.setConsultation(data.rows);
				informationProcess.setConsultationTotalCount(data.count);
			})
			.then(() =>
				fetchInitiations(1, 5)
					.then((data) => {
						informationProcess.setInitiation(data.rows);
						informationProcess.setInitiationTotalCount(data.count);
					})
					.then(() =>
						fetchRecommendations(1, 5)
							.then((data) => {
								informationProcess.setRecommendations(data.rows);
								informationProcess.setRecommendationsTotalCount(data.count);
							})
							.then(() =>
								fetchDraftDecisions(1, 2)
									.then((data) => {
										informationProcess.setDraftDecisions(data.rows);
										informationProcess.setDraftDecisionsTotalCount(data.count);
									})
									.finally(() => setLoading(false))
							)
					)
			);
	}, [informationProcess]);

	useEffect(() => {
		fetchConsultations(informationProcess.consultationPage, informationProcess.consultationLimit)
			.then((data) => {
				informationProcess.setConsultation(data.rows);
				informationProcess.setConsultationTotalCount(data.count);
			})
			.then(() =>
				fetchInitiations(informationProcess.initiationPage, informationProcess.initiationLimit)
					.then((data) => {
						informationProcess.setInitiation(data.rows);
						informationProcess.setInitiationTotalCount(data.count);
					})
					.then(() =>
						fetchRecommendations(informationProcess.recommendationsPage, informationProcess.recommendationsLimit)
							.then((data) => {
								informationProcess.setRecommendations(data.rows);
								informationProcess.setRecommendationsTotalCount(data.count);
							})
							.then(() =>
								fetchDraftDecisions(informationProcess.draftDecisionsPage, informationProcess.draftDecisionsLimit)
									.then((data) => {
										informationProcess.setDraftDecisions(data.rows);
										informationProcess.setDraftDecisionsTotalCount(data.count);
									})
									.finally(() => setLoading(false))
							)
					)
			);
	}, [
		informationProcess.consultationPage,
		informationProcess.initiationPage,
		informationProcess.recommendationsPage,
		informationProcess.draftDecisionsPage,
	]);

	const consultationPageCount = Math.ceil(informationProcess.consultationTotalCount / informationProcess.consultationLimit);
	const initiationPageCount = Math.ceil(informationProcess.initiationTotalCount / informationProcess.initiationLimit);
	const recommendationsPageCount = Math.ceil(informationProcess.recommendationsTotalCount / informationProcess.recommendationsLimit);
	const draftDecisionsPageCount = Math.ceil(informationProcess.draftDecisionsTotalCount / informationProcess.draftDecisionsLimit);

	for (let i = 1; i <= consultationPageCount; i++) {
		consultationPages.push(i);
	}
	for (let i = 1; i <= initiationPageCount; i++) {
		initiationPages.push(i);
	}
	for (let i = 1; i <= recommendationsPageCount; i++) {
		recommendationsPages.push(i);
	}
	for (let i = 1; i <= draftDecisionsPageCount; i++) {
		draftDecisionsPages.push(i);
	}

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-md-row flex-column justify-content-around px-4 border-bottom'>
			<div className='d-flex flex-column px-5 py-4 col-md-8'>
				<div className='block-title pb-4'>
					<span>Procesul decizional</span>
				</div>
				<Tabs
					defaultActiveKey='consultation'
					id='uncontrolled-tab-example'
					className='d-flex align-items-center justify-content-center mb-3 text-primary fw-bold'>
					<Tab
						eventKey='consultation'
						title='Consultare publică'>
						{informationProcess.consultation.map((item, idx) => (
							<Link
								key={"consultation" + idx}
								className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
								to={PUBLIC_CONSULTATIONS_ROUTE + "/" + item.id}>
								<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
								<div className='d-flex justify-content-start py-2'>
									<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
								</div>
							</Link>
						))}
						<Pagination className='d-flex justify-content-center align-items-center mt-5'>
							{consultationPages.map((page) => (
								<Pagination.Item
									key={page}
									active={informationProcess.consultationPage === page}
									onClick={() => informationProcess.setConsultationPage(page)}>
									{page}
								</Pagination.Item>
							))}
						</Pagination>
					</Tab>
					<Tab
						eventKey='initiation'
						title='Inițiere proiect'>
						{informationProcess.initiation.map((item, idx) => (
							<Link
								key={"initiation" + idx}
								className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
								to={INITIATION_ROUTE + "/" + item.id}>
								<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
								<div className='d-flex justify-content-start py-2'>
									<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
								</div>
							</Link>
						))}
						<Pagination className='d-flex justify-content-center align-items-center mt-5'>
							{initiationPages.map((page) => (
								<Pagination.Item
									key={page}
									active={informationProcess.initiationPage === page}
									onClick={() => informationProcess.setInitiationPage(page)}>
									{page}
								</Pagination.Item>
							))}
						</Pagination>
					</Tab>
					<Tab
						eventKey='draftDecisions'
						title='Proiecte de Decizii'>
						{informationProcess.draftDecisions.map((item, idx) => (
							<Link
								key={"draft_decision" + idx}
								className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
								to={DRAFT_DECISIONS_ROUTE + "/" + item.id}>
								<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
								<div className='d-flex justify-content-start py-2'>
									<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
								</div>
							</Link>
						))}
						<Pagination className='d-flex justify-content-center align-items-center mt-5'>
							{draftDecisionsPages.map((page) => (
								<Pagination.Item
									key={page}
									active={informationProcess.draftDecisionsPage === page}
									onClick={() => informationProcess.setDraftDecisionsPage(page)}>
									{page}
								</Pagination.Item>
							))}
						</Pagination>
					</Tab>
					<Tab
						eventKey='recommendations'
						title='Sinteza recomandărilor'>
						{informationProcess.recommendations.map((item, idx) => (
							<Link
								key={"recommendations" + idx}
								className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
								to={RECOMENDATIONS_ROUTE + "/" + item.id}>
								<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
								<div className='d-flex justify-content-start py-2'>
									<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
								</div>
							</Link>
						))}
						<Pagination className='d-flex justify-content-center align-items-center mt-5'>
							{recommendationsPages.map((page) => (
								<Pagination.Item
									key={page}
									active={informationProcess.recommendationsPage === page}
									onClick={() => informationProcess.setRecommendationsPage(page)}>
									{page}
								</Pagination.Item>
							))}
						</Pagination>
					</Tab>
				</Tabs>
			</div>
			<Calendar />
		</div>
	);
});

export default DecisionProcess;
