import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const createTenders = async (tender) => {
	const { data } = await $authHost.post("api/tenders", tender);
	return data;
};

export const fetchTenders = async (page, limit) => {
	const { data } = await $host.get("api/tenders", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOneTenders = async (id) => {
	const { data } = await $host.get("api/tenders/" + id);
	return data;
};

export const removeOneTenders = async (id) => {
	const { data } = await $host.post("api/tenders/" + id);
	return data;
};

export const removeOneTendersFile = async (id) => {
	const { data } = await $host.post("api/tenders/rD/" + id);
	return data;
};

export const updateOneTenders = async (processData, id) => {
	const { data } = await $host.post("api/tenders/u/" + id, processData);
	return data;
};

// Next Element

export const createAcquisition = async (acquisition) => {
	const { data } = await $authHost.post("api/acquisition", acquisition);
	return data;
};

export const fetchAcquisition = async (page, limit) => {
	const { data } = await $host.get("api/acquisition", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOneAcquisition = async (id) => {
	const { data } = await $host.get("api/acquisition/" + id);
	return data;
};

export const removeOneAcquisition = async (id) => {
	const { data } = await $host.post("api/acquisition/" + id);
	return data;
};

export const removeOneAcquisitionFile = async (id) => {
	const { data } = await $host.post("api/acquisition/rD/" + id);
	return data;
};

export const updateOneAcquisition = async (processData, id) => {
	const { data } = await $host.post("api/acquisition/u/" + id, processData);
	return data;
};

// Next Element

export const createVacancies = async (vacancies) => {
	const { data } = await $authHost.post("api/vacancies", vacancies);
	return data;
};

export const fetchVacancies = async (page, limit) => {
	const { data } = await $host.get("api/vacancies", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOneVacancies = async (id) => {
	const { data } = await $host.get("api/vacancies/" + id);
	return data;
};

export const removeOneVacancies = async (id) => {
	const { data } = await $host.post("api/vacancies/" + id);
	return data;
};

export const removeOneVacanciesFile = async (id) => {
	const { data } = await $host.post("api/vacancies/rD/" + id);
	return data;
};

export const updateOneVacancies = async (processData, id) => {
	const { data } = await $host.post("api/vacancies/u/" + id, processData);
	return data;
};

// Next Element

export const createConsultation = async (consultation) => {
	const { data } = await $authHost.post("api/public_consultations", consultation);
	return data;
};

export const fetchConsultations = async (page, limit) => {
	const { data } = await $host.get("api/public_consultations", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOneConsultation = async (id) => {
	const { data } = await $host.get("api/public_consultations/" + id);
	return data;
};

export const removeOneConsultation = async (id) => {
	const { data } = await $host.post("api/public_consultations/" + id);
	return data;
};

export const removeOneConsultationFile = async (id) => {
	const { data } = await $host.post("api/public_consultations/rD/" + id);
	return data;
};

export const updateOneConsultation = async (processData, id) => {
	const { data } = await $host.post("api/public_consultations/u/" + id, processData);
	return data;
};

// Next Element

export const createInitiation = async (initiation) => {
	const { data } = await $authHost.post("api/initiation", initiation);
	return data;
};

export const fetchInitiations = async (page, limit) => {
	const { data } = await $host.get("api/initiation", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOneInitiation = async (id) => {
	const { data } = await $host.get("api/initiation/" + id);
	return data;
};

export const removeOneInitiation = async (id) => {
	const { data } = await $host.post("api/initiation/" + id);
	return data;
};

export const removeOneInitiationFile = async (id) => {
	const { data } = await $host.post("api/initiation/rD/" + id);
	return data;
};

export const updateOneInitiation = async (processData, id) => {
	const { data } = await $host.post("api/initiation/u/" + id, processData);
	return data;
};

// Next Element

export const createRecommendations = async (recommendations) => {
	const { data } = await $authHost.post("api/recommendations", recommendations);
	return data;
};

export const fetchRecommendations = async (page, limit) => {
	const { data } = await $host.get("api/recommendations", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOneRecommendations = async (id) => {
	const { data } = await $host.get("api/recommendations/" + id);
	return data;
};

export const removeOneRecommendations = async (id) => {
	const { data } = await $host.post("api/recommendations/" + id);
	return data;
};

export const removeOneRecommendationsFile = async (id) => {
	const { data } = await $host.post("api/recommendations/rD/" + id);
	return data;
};

export const updateOneRecommendations = async (processData, id) => {
	const { data } = await $host.post("api/recommendations/u/" + id, processData);
	return data;
};

// Next Element

export const createsPublicNotices = async (publicNotices) => {
	const { data } = await $authHost.post("api/public_notices", publicNotices);
	return data;
};

export const fetchPublicNotices = async (page, limit) => {
	const { data } = await $host.get("api/public_notices", {
		params: {
			page,
			limit,
		},
	});
	return data;
};

export const fetchOnePublicNotices = async (id) => {
	const { data } = await $host.get("api/public_notices/" + id);
	return data;
};

export const removeOnePublicNotices = async (id) => {
	const { data } = await $host.post("api/public_notices/" + id);
	return data;
};

export const removeOnePublicNoticesFile = async (id) => {
	const { data } = await $host.post("api/public_notices/rD/" + id);
	return data;
};

export const updateOnePublicNotices = async (processData, id) => {
	const { data } = await $host.post("api/public_notices/u/" + id, processData);
	return data;
};
