import { makeAutoObservable } from "mobx";

export default class CityNewsStore {
	constructor() {
		this._cityNews = [
			//   {
			//     id: 1,
			//     img: "https://picsum.photos/400/250",
			//     title:
			//       "Moldovan Farmers Grapple with Rising Inflation and Supply Chain Disruptions",
			//     article:
			//       "Moldova's agricultural sector is facing significant challenges due to rising inflation and supply chain disruptions caused by the war in Ukraine. Farmers are struggling to access essential inputs such as fertilizers and pesticides, while the cost of transportation has also increased sharply. These factors are putting pressure on food prices, which have already risen significantly in recent months.",
			//     date: "30.01.2024 11:19",
			//     isNew: true,
			//     city_news_photo_collection: [
			//       {
			//         img: "https://picsum.photos/1900/1080",
			//         img_description: "Some description for random-generated image 1",
			//       },
			//       {
			//         img: "https://picsum.photos/1900/1080",
			//         img_description: "Some description for random-generated image 2",
			//       },
			//       {
			//         img: "https://picsum.photos/1900/1080",
			//         img_description: "Some description for random-generated image 3",
			//       },
			//     ],
			//   },
		];

		this._committeeMeetings = [
			//   {
			//     id: 1,
			//     img: "https://picsum.photos/400/250",
			//     title:
			//       "Moldovan Farmers Grapple with Rising Inflation and Supply Chain Disruptions",
			//     article:
			//       "Moldova's agricultural sector is facing significant challenges due to rising inflation and supply chain disruptions caused by the war in Ukraine. Farmers are struggling to access essential inputs such as fertilizers and pesticides, while the cost of transportation has also increased sharply. These factors are putting pressure on food prices, which have already risen significantly in recent months.",
			//     date: "30.01.2024 11:19",
			//     isNew: true,
			//   },
		];

		this._photoGallery = [
			//   {
			//     id: 1,
			//     title: "Fotografii ale teritoriului districtului",
			//     photo_collection: [
			//       {
			//         img: "https://picsum.photos/1900/1080",
			//         img_description: "Some description for random-generated image 1",
			//       },
			//       {
			//         img: "https://picsum.photos/1900/1080",
			//         img_description: "Some description for random-generated image 2",
			//       },
			//       {
			//         img: "https://picsum.photos/1900/1080",
			//         img_description: "Some description for random-generated image 3",
			//       },
			//     ],
			//     author: "Huan D.",
			//     date: "30.01.2024 11:19",
			//     isNew: false,
			//   },
		];

		this._onlineMeetings = [
			//   {
			//     id: 1,
			//     title: "Ședință dedicată rezolvării problemei animalelor vagabonde",
			//     video_link:
			//       "https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg",
			//     date: "30.01.2024 11:19",
			//     isNew: false,
			//     article:
			//       "În data de 8 august 2024, a avut loc o ședință esențială dedicată abordării problemelor legate de animalele vagabonde. Evenimentul a reunit reprezentanți ai autorităților locale, organizații non-guvernamentale, experți în protecția animalelor, și membri ai comunității, având ca obiectiv identificarea soluțiilor durabile pentru această problemă complexă.",
			//   },
		];

		this._mayorOnline = [
			//   {
			//     id: 1,
			//     title: "Mesajul Primarului",
			//     video_link: "https://www.youtube.com/embed/ppCkVD_M5m4",
			//     date: "30.01.2024 11:19",
			//     isNew: false,
			//     article: [
			//       "În data de 8 august 2024, a avut loc o ședință esențială dedicată abordării problemelor legate de animalele vagabonde. Evenimentul a reunit reprezentanți ai autorităților locale, organizații non-guvernamentale, experți în protecția animalelor, și membri ai comunității, având ca obiectiv identificarea soluțiilor durabile pentru această problemă complexă.",
			//       "Problema animalelor vagabonde a devenit tot mai acută în ultimele luni, cu impact semnificativ asupra siguranței publice, sănătății și bunăstării animalelor. În contextul creșterii numărului de animale fără stăpân, autoritățile și organizațiile implicate au recunoscut necesitatea unei abordări coordonate și bine fundamentate.",
			//     ],
			//   },
		];

		this._other = [
			//   {
			//     id: 1,
			//     title: "Mesajul Primarului",
			//     video_link: "https://www.youtube.com/embed/ppCkVD_M5m4",
			//     date: "30.01.2024 11:19",
			//     isNew: false,
			//     article: [
			//       "În data de 8 august 2024, a avut loc o ședință esențială dedicată abordării problemelor legate de animalele vagabonde. Evenimentul a reunit reprezentanți ai autorităților locale, organizații non-guvernamentale, experți în protecția animalelor, și membri ai comunității, având ca obiectiv identificarea soluțiilor durabile pentru această problemă complexă.",
			//       "Problema animalelor vagabonde a devenit tot mai acută în ultimele luni, cu impact semnificativ asupra siguranței publice, sănătății și bunăstării animalelor. În contextul creșterii numărului de animale fără stăpân, autoritățile și organizațiile implicate au recunoscut necesitatea unei abordări coordonate și bine fundamentate.",
			//     ],
			//   },
		];
		this._cityNewsPage = 1;
		this._committeeMeetingsPage = 1;
		this._photoGalleryPage = 1;
		this._onlineMeetingsPage = 1;
		this._mayorOnlinePage = 1;
		this._otherPage = 1;

		this._cityNewsTotalCount = 0;
		this._committeeMeetingsTotalCount = 0;
		this._photoGalleryTotalCount = 0;
		this._onlineMeetingsTotalCount = 0;
		this._mayorOnlineTotalCount = 0;
		this._otherTotalCount = 0;

		this._cityNewsLimit = 10;
		this._committeeMeetingsLimit = 10;
		this._photoGalleryLimit = 10;
		this._onlineMeetingsLimit = 10;
		this._mayorOnlineLimit = 10;
		this._otherLimit = 10;

		// this._page = 1;
		// this._totalCount = 0;
		// this._limit = 10;
		makeAutoObservable(this);
	}

	setCityNews(cityNews) {
		this._cityNews = cityNews;
	}
	get cityNews() {
		return this._cityNews;
	}

	setCommitteeMeetings(committeeMeetings) {
		this._committeeMeetings = committeeMeetings;
	}
	get committeeMeetings() {
		return this._committeeMeetings;
	}

	setPhotoGallery(photoGallery) {
		this._photoGallery = photoGallery;
	}
	get photoGallery() {
		return this._photoGallery;
	}

	setOnlineMeetings(onlineMeetings) {
		this._onlineMeetings = onlineMeetings;
	}
	get onlineMeetings() {
		return this._onlineMeetings;
	}

	setMayorOnline(mayorOnline) {
		this._mayorOnline = mayorOnline;
	}
	get mayorOnline() {
		return this._mayorOnline;
	}

	setOther(other) {
		this._other = other;
	}
	get other() {
		return this._other;
	}

	setCityNewsTotalCount(cityNewsTotalCount) {
		this._cityNewsTotalCount = cityNewsTotalCount;
	}
	get cityNewsTotalCount() {
		return this._cityNewsTotalCount;
	}

	setCommitteeMeetingsTotalCount(committeeMeetingsTotalCount) {
		this._committeeMeetingsTotalCount = committeeMeetingsTotalCount;
	}
	get committeeMeetingsTotalCount() {
		return this._committeeMeetingsTotalCount;
	}

	setPhotoGalleryTotalCount(photoGalleryTotalCount) {
		this._photoGalleryTotalCount = photoGalleryTotalCount;
	}
	get photoGalleryTotalCount() {
		return this._photoGalleryTotalCount;
	}

	setOnlineMeetingsTotalCount(onlineMeetingsTotalCount) {
		this._onlineMeetingsTotalCount = onlineMeetingsTotalCount;
	}
	get onlineMeetingsTotalCount() {
		return this._onlineMeetingsTotalCount;
	}

	setMayorOnlineTotalCount(mayorOnlineTotalCount) {
		this._mayorOnlineTotalCount = mayorOnlineTotalCount;
	}
	get mayorOnlineTotalCount() {
		return this._mayorOnlineTotalCount;
	}

	setOtherTotalCount(otherTotalCount) {
		this._otherTotalCount = otherTotalCount;
	}
	get otherTotalCount() {
		return this._otherTotalCount;
	}

	setCityNewsLimit(cityNewsLimit) {
		this._cityNewsLimit = cityNewsLimit;
	}
	get cityNewsLimit() {
		return this._cityNewsLimit;
	}

	setCommitteeMeetingsLimit(committeeMeetingsLimit) {
		this._committeeMeetingsLimit = committeeMeetingsLimit;
	}
	get committeeMeetingsLimit() {
		return this._committeeMeetingsLimit;
	}

	setPhotoGalleryLimit(photoGalleryLimit) {
		this._photoGalleryLimit = photoGalleryLimit;
	}
	get photoGalleryLimit() {
		return this._photoGalleryLimit;
	}

	setOnlineMeetingsLimit(onlineMeetingsLimit) {
		this._onlineMeetingsLimit = onlineMeetingsLimit;
	}
	get onlineMeetingsLimit() {
		return this._onlineMeetingsLimit;
	}

	setMayorOnlineLimit(mayorOnlineLimit) {
		this._mayorOnlineLimit = mayorOnlineLimit;
	}
	get mayorOnlineLimit() {
		return this._mayorOnlineLimit;
	}

	setOtherLimit(otherLimit) {
		this._otherLimit = otherLimit;
	}
	get otherLimit() {
		return this._otherLimit;
	}

	setCityNewsPage(cityNewsPage) {
		this._cityNewsPage = cityNewsPage;
	}
	get cityNewsPage() {
		return this._cityNewsPage;
	}

	setCommitteeMeetingsPage(committeeMeetingsPage) {
		this._committeeMeetingsPage = committeeMeetingsPage;
	}
	get committeeMeetingsPage() {
		return this._committeeMeetingsPage;
	}

	setPhotoGalleryPage(photoGalleryPage) {
		this._photoGalleryPage = photoGalleryPage;
	}
	get photoGalleryPage() {
		return this._photoGalleryPage;
	}

	setOnlineMeetingsPage(onlineMeetingsPage) {
		this._onlineMeetingsPage = onlineMeetingsPage;
	}
	get onlineMeetingsPage() {
		return this._onlineMeetingsPage;
	}

	setMayorOnlinePage(mayorOnlinePage) {
		this._mayorOnlinePage = mayorOnlinePage;
	}
	get mayorOnlinePage() {
		return this._mayorOnlinePage;
	}

	setOtherPage(otherPage) {
		this._otherPage = otherPage;
	}
	get otherPage() {
		return this._otherPage;
	}
}
