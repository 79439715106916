import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, Card } from "react-bootstrap";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import {
	CITY_NEWS_ROUTE,
	COMMITTEE_MEETINGS_ROUTE,
	HEAD_ONLINE_ROUTE,
	ONLINE_MEETING_ROUTE,
	OTHER_ROUTE,
	PHOTO_GALLERY_ROUTE,
} from "../utils/consts";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
	fetchCityNews,
	fetchCommitteeMeetings,
	fetchMayorOnline,
	fetchOnlineMeetings,
	fetchOther,
	fetchPhotoGallery,
} from "../http/NewsAPI";
import city_news_img from "../assets/city_news.png";

const CityNews = observer(() => {
	const { cityNews } = useContext(Context);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchCityNews().then((data) => {
			cityNews.setCityNews(data);
		});
		fetchCommitteeMeetings().then((data) => {
			cityNews.setCommitteeMeetings(data);
		});
		fetchPhotoGallery().then((data) => {
			cityNews.setPhotoGallery(data);
		});
		fetchOnlineMeetings().then((data) => {
			cityNews.setOnlineMeetings(data);
		});
		fetchMayorOnline().then((data) => {
			cityNews.setMayorOnline(data);
		});
		fetchOther()
			.then((data) => {
				cityNews.setOther(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="py-3 px-xl-5  px-2 border-bottom">
			<div className="py-5 block-title">
				<span>Noutațile AVVRM</span>
			</div>
			<Tabs
				defaultActiveKey="news"
				id="fill-tab-example"
				className="d-flex align-items-center justify-content-center mb-3 text-primary fw-bold"
				fill
			>
				<Tab eventKey="news" title="Noutăți" className="text-primary fw-bold">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{cityNews.cityNews.count > 0
							? cityNews.cityNews.rows.map((item, idx) => {
									if (idx < 4) {
										return (
											<Card
												key={idx}
												className="my-3 shadow-sm border-0"
												onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
												style={{ width: "22rem", minHeight: "20rem" }}
												role="button"
											>
												<div
													className="h-100 rounded"
													style={{
														minHeight: "20vh",
														background: `url(${
															item.city_news_files.length > 0
																? process.env.REACT_APP_API_URL +
																  item.city_news_files[0].img
																: city_news_img
														})  center center/cover no-repeat`,
													}}
												></div>
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text className="text-primary truncate">
														{item.title}
													</Card.Text>
												</Card.Body>
											</Card>
										);
									} else return null;
							  })
							: null}
					</div>
				</Tab>
				<Tab eventKey="commission-meetings" title="Ședințe comisii" className="text-primary fw-bold">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{cityNews.committeeMeetings.count > 0
							? cityNews.committeeMeetings.rows.map((item, idx) => {
									if (idx < 4) {
										return (
											<Card
												key={idx}
												className="my-3 shadow-sm border-0"
												onClick={() => navigate(COMMITTEE_MEETINGS_ROUTE + "/" + item.id)}
												style={{ width: "22rem", minHeight: "20rem" }}
												role="button"
											>
												<div
													className="h-100 rounded"
													style={{
														minHeight: "20vh",
														background: `url(${
															process.env.REACT_APP_API_URL + item.img
														})  center center/cover no-repeat`,
													}}
												></div>
												{/* <Card.Img
												variant='top'
												src={process.env.REACT_APP_API_URL + item.img}
											/> */}
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text className="truncate">{item.title}</Card.Text>
												</Card.Body>
											</Card>
										);
									}
							  })
							: null}
					</div>
				</Tab>
				<Tab eventKey="photos" title="Fotografii" className="text-primary fw-bold">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{cityNews.photoGallery.count > 0
							? cityNews.photoGallery.rows.map((item, idx) => {
									if (idx < 4) {
										return (
											<Card
												key={idx}
												className="my-3 shadow-sm border-0"
												onClick={() => navigate(PHOTO_GALLERY_ROUTE + "/" + item.id)}
												style={{ width: "22rem", minHeight: "20rem" }}
												role="button"
											>
												<div
													className="h-100 rounded"
													style={{
														minHeight: "20vh",
														background: `url(${
															item.photo_gallery_files.length > 0
																? process.env.REACT_APP_API_URL +
																  item.photo_gallery_files[0].img
																: city_news_img
														})  center center/cover no-repeat`,
													}}
												></div>
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text className="truncate">{item.title}</Card.Text>
												</Card.Body>
											</Card>
										);
									} else return null;
							  })
							: null}
					</div>
				</Tab>
				<Tab eventKey="online-meetings" title="Ședințe online" className="text-primary fw-bold">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{cityNews.onlineMeetings.count > 0
							? cityNews.onlineMeetings.rows.map((item, idx) => {
									if (idx < 4) {
										return (
											<Card
												key={idx}
												className="my-3 shadow-sm border-0"
												onClick={() => navigate(ONLINE_MEETING_ROUTE + "/" + item.id)}
												style={{ width: "22rem", minHeight: "20rem" }}
												role="button"
											>
												<div
													className="h-100 rounded"
													style={{
														minHeight: "20vh",
														background: `url(${
															item.link.includes("fb.watch")
																? "https://1000logos.net/wp-content/uploads/2016/11/fb-logo.jpg"
																: "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
														})  center center/cover no-repeat`,
													}}
												></div>
												{/* <Card.Img
												variant='top'
												src={
													item.link.includes("facebook")
														? "https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg"
														: "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
												}
											/> */}
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text className="truncate">{item.title}</Card.Text>
												</Card.Body>
											</Card>
										);
									}
							  })
							: null}
					</div>
				</Tab>
				<Tab eventKey="mayor-online" title="Primarul online" className="text-primary fw-bold">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{cityNews.mayorOnline.count > 0
							? cityNews.mayorOnline.rows.map((item, idx) => {
									if (idx < 4) {
										return (
											<Card
												key={idx}
												className="my-3 shadow-sm border-0"
												onClick={() => navigate(HEAD_ONLINE_ROUTE + "/" + item.id)}
												role="button"
												style={{ width: "22rem", minHeight: "20rem" }}
											>
												<div
													className="h-100 rounded"
													style={{
														minHeight: "20vh",
														background: `url(${
															item.link.includes("fb.watch")
																? "https://1000logos.net/wp-content/uploads/2016/11/fb-logo.jpg"
																: "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
														})  center center/cover no-repeat`,
													}}
												></div>
												{/* <Card.Img
												variant='top'
												src={
													item.link.includes("facebook")
														? "https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg"
														: "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
												}
											/> */}
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text className="truncate">{item.title}</Card.Text>
												</Card.Body>
											</Card>
										);
									}
							  })
							: null}
					</div>
				</Tab>
				<Tab eventKey="other" title="Alte" className="text-primary fw-bold">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{cityNews.other.count > 0
							? cityNews.other.rows.map((item, idx) => {
									if (idx < 4) {
										return (
											<Card
												key={idx}
												className="my-3 shadow-sm border-0"
												onClick={() => navigate(OTHER_ROUTE + "/" + item.id)}
												role="button"
												style={{ width: "22rem", minHeight: "20rem" }}
											>
												<div
													className="h-100 rounded"
													style={{
														minHeight: "20vh",
														background: `url(${
															item.link.includes("fb.watch")
																? "https://1000logos.net/wp-content/uploads/2016/11/fb-logo.jpg"
																: "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
														})  center center/cover no-repeat`,
													}}
												></div>
												{/* <Card.Img
												variant='top'
												src={
													item.link.includes("facebook")
														? "https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg"
														: "https://assets.turbologo.com/blog/en/2019/10/19084944/youtube-logo-illustration-958x575.jpg"
												}
											/> */}
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text className="truncate">{item.title}</Card.Text>
												</Card.Body>
											</Card>
										);
									}
							  })
							: null}
					</div>
				</Tab>
			</Tabs>
			<div className="d-flex justify-content-center my-4">
				<Link
					className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
					to={CITY_NEWS_ROUTE}
				>
					Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
				</Link>
			</div>
		</div>
	);
});

export default CityNews;
