import { useEffect, useState } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton, Tabs, Tab, Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
	BIOGRAPHY_ROUTE,
	MEETINGS_ROUTE,
	PRESIDENTS_MESSAGE_ROUTE,
	REGULATIONS_ROUTE,
	SPECIALIZED_COMMITTEES_ROUTE,
	VERBAL_PROCEDURE_ROUTE,
} from "../../utils/consts";
import {
	fetchBiography,
	fetchMeetings,
	fetchOneOrganization,
	fetchRegulations,
	fetchSpecialCommittees,
	fetchSpecialCommitteesMeets,
	fetchVerbalProcedure,
	removeOneBiography,
	removeOneBiographyStudy,
	removeOneIntership,
	removeOneLanguage,
	removeOneMeetings,
	removeOneMeetingsFile,
	removeOneMember,
	removeOneOrganization,
	removeOneProfExperience,
	removeOneRegulations,
	removeOneRegulationsFile,
	removeOneSpecialCommittees,
	removeOneSpecialCommitteesMeets,
	removeOneSpecialCommitteesMeetsFile,
	removeOneVerbalProcedure,
	removeOneVerbalProcedureFile,
	updateOneBiography,
	updateOneMeetings,
	updateOneOrganization,
	updateOneRegulations,
	updateOneSpecialCommittees,
	updateOneSpecialCommitteesMeets,
	updateOneVerbalProcedure,
} from "../../http/navLinksAPI";
import AddAdminModal from "./Modals/AddAdminModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";

const AdministrationAdmin = observer(() => {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [biography, setBiography] = useState([null]);
	const [organization, setOrganization] = useState([null]);
	const [committees, setCommittees] = useState([null]);
	const [regulations, setRegulations] = useState([null]);
	const [meetings, setMeetings] = useState([null]);
	const [verbalProcedure, setVerbalProcedure] = useState([null]);
	const [specialCommitteesMeets, setSpecialCommitteesMeets] = useState([null]);

	// DS

	const [name, setName] = useState("");
	const [fullName, setFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [address, setAddress] = useState("");
	const [article, setArticle] = useState("");

	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState("Conducerea");

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [author, setAuthor] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className='btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2'
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [role, setRole] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [fax, setFax] = useState("");
	const [email, setEmail] = useState("");

	const [interships, setInterships] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [profExperiences, setProfExperiences] = useState([]);
	const [studies, setStudies] = useState([]);

	const [intershipsChanges, setIntershipsChanges] = useState([]);
	const [languagesChanges, setLanguagesChanges] = useState([]);
	const [profExperiencesChanges, setProfExperiencesChanges] = useState([]);
	const [studiesChanges, setStudiesChanges] = useState([]);

	// CommitteeMembers
	const [committeeTitle, setCommitteeTitle] = useState("");
	const [committeeMembers, setCommitteeMembers] = useState([]);
	const [committeeMembersChanges, setCommitteeMembersChanges] = useState([]);

	// BioData Start
	const addInterships = () => {
		setInterships([...interships, { title: "", description: "", number: `tmp_${Date.now()}` }]);
	};

	const removeInterships = (number) => {
		setInterships(interships.filter((i) => i.number !== number));
	};

	const changeInterships = (key, value, number) => {
		setInterships(interships.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const removeIntershipsChanges = (sub_item) => {
		const elem_id = sub_item.id;
		removeOneIntership(elem_id).then(() =>
			setBiography((old_data) => {
				const updated_data = biography.rows.map((bio) => ({
					...bio,
					biography_interships: bio.biography_interships.filter((intership) => intership.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const changeIntershipsChanges = (sub_item, key, value) => {
		const escapeDublication = intershipsChanges.some((item) => item.number === sub_item.id);
		const newIntership = {
			title: sub_item.title,
			description: sub_item.description,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setIntershipsChanges((prevIntership) => [...prevIntership, newIntership]);
		} else {
			setIntershipsChanges((prevIntership) => prevIntership.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
		console.log(...intershipsChanges);
	};
	// Next
	const addLanguages = () => {
		setLanguages([...languages, { title: "", description: "", number: `tmp_${Date.now()}` }]);
	};

	const removeLanguages = (number) => {
		setLanguages(languages.filter((i) => i.number !== number));
	};

	const changeLanguages = (key, value, number) => {
		setLanguages(languages.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const removeLanguagesChanges = (sub_item) => {
		const elem_id = sub_item.id;
		removeOneLanguage(elem_id).then(() =>
			setBiography((old_data) => {
				const updated_data = biography.rows.map((bio) => ({
					...bio,
					biography_languages: bio.biography_languages.filter((lang) => lang.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const changeLanguagesChanges = (sub_item, key, value) => {
		const escapeDublication = languagesChanges.some((item) => item.number === sub_item.id);
		const newLang = {
			title: sub_item.title,
			description: sub_item.description,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setLanguagesChanges((prevLang) => [...prevLang, newLang]);
		} else {
			setLanguagesChanges((prevLang) => prevLang.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
		console.log(...languagesChanges);
	};
	// Next
	const addProfExperiences = () => {
		setProfExperiences([...profExperiences, { title: "", description: "", number: `tmp_${Date.now()}` }]);
	};

	const removeProfExperiences = (number) => {
		setProfExperiences(profExperiences.filter((i) => i.number !== number));
	};

	const changeProfExperiences = (key, value, number) => {
		setProfExperiences(profExperiences.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const removeProfExperiencesChanges = (sub_item) => {
		const elem_id = sub_item.id;
		removeOneProfExperience(elem_id).then(() =>
			setBiography((old_data) => {
				const updated_data = biography.rows.map((bio) => ({
					...bio,
					biography_prof_experiences: bio.biography_prof_experiences.filter((prof) => prof.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const changeProfExperiencesChanges = (sub_item, key, value) => {
		const escapeDublication = profExperiencesChanges.some((item) => item.number === sub_item.id);
		const newProf = {
			title: sub_item.title,
			description: sub_item.description,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setProfExperiencesChanges((prevProf) => [...prevProf, newProf]);
		} else {
			setProfExperiencesChanges((prevProf) => prevProf.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
		console.log(...profExperiencesChanges);
	};
	// Next
	const addStudies = () => {
		setStudies([...studies, { title: "", description: "", number: `tmp_${Date.now()}` }]);
	};

	const removeStudies = (number) => {
		setStudies(studies.filter((i) => i.number !== number));
	};

	const changeStudies = (key, value, number) => {
		setStudies(studies.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const removeStudiesChanges = (sub_item) => {
		const elem_id = sub_item.id;
		removeOneBiographyStudy(elem_id).then(() =>
			setBiography((old_data) => {
				const updated_data = biography.rows.map((bio) => ({
					...bio,
					biography_studies: bio.biography_studies.filter((study) => study.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const changeStudiesChanges = (sub_item, key, value) => {
		const escapeDublication = studiesChanges.some((item) => item.number === sub_item.id);
		const newStudy = {
			title: sub_item.title,
			description: sub_item.description,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setStudiesChanges((prevStudies) => [...prevStudies, newStudy]);
		} else {
			setStudiesChanges((prevStudies) => prevStudies.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
		console.log(...studiesChanges);
	};

	const addCommitteeMember = () => {
		setCommitteeMembers([...committeeMembers, { full_name: "", party_name: "", number: Date.now() }]);
	};

	const removeCommitteeMember = (number) => {
		setCommitteeMembers(committeeMembers.filter((i) => i.number !== number));
	};

	const changeCommitteeMember = (key, value, number) => {
		setCommitteeMembers(committeeMembers.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
	};

	const removeCommitteeMemberChanges = (sub_item) => {
		// setCommitteeMembersChanges(committeeMembersChanges.filter((i) => i.number !== number));
		const elem_id = sub_item.id;
		removeOneMember(elem_id).then(() =>
			setCommittees((old_data) => {
				const updated_data = committees.rows.map((committee) => ({
					...committee,
					special_committees_members: committee.special_committees_members.filter((member) => member.id !== elem_id),
				}));

				return { ...old_data, rows: updated_data };
			})
		);
	};

	const changeCommitteeMemberChanges = (sub_item, key, value) => {
		const escapeDublication = committeeMembersChanges.some((item) => item.number === sub_item.id);
		const newMember = {
			full_name: sub_item.full_name,
			party_name: sub_item.party_name,
			number: sub_item.id,
		};
		if (!escapeDublication) {
			setCommitteeMembersChanges((prevMember) => [...prevMember, newMember]);
		} else {
			setCommitteeMembersChanges((prevMember) => prevMember.map((i) => (i.number === sub_item.id ? { ...i, [key]: value } : i)));
		}
		console.log(...committeeMembersChanges);
	};

	// BioData End

	// const removeExistingElement = (item, sub_item, section) => {
	// 	const id = item.id;
	// 	const elem_id = sub_item.id;
	// 	switch (section) {
	// 		case "studii":
	// 			removeOneBiographyStudies(elem_id).then(() =>
	// 				setBiography(biography.rows[id].biography_studies.filter((i) => i.id !== elem_id))
	// 			);
	// 			break;
	// 	}
	// };

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	const editItem = (data) => {
		const id = data.id;
		const formData = new FormData();
		if (title != "") formData.append("title", title);
		if (description != "") formData.append("description", description);
		if (img != null) formData.append("img", img);
		if (author != "") formData.append("author", author);

		// DS

		if (name != "") formData.append("name", name);
		if (fullName != "") formData.append("full_name", fullName);
		if (phone != "") formData.append("phone", phone);
		if (type != "") formData.append("type", type);
		if (address != "") formData.append("address", address);
		if (contactPerson != "") formData.append("contact_person", contactPerson);
		if (article != "") formData.append("article", article);

		if (firstName != "") formData.append("first_name", firstName);
		if (lastName != "") formData.append("last_name", lastName);
		if (role != "") formData.append("role", role);
		if (birthdate != "") formData.append("birthdate", birthdate);
		if (fax != "") formData.append("fax", fax);
		if (email != "") formData.append("email", email);

		if (committeeTitle != "") formData.append("committeeTitle", committeeTitle);
		if (committeeMembers != "") formData.append("committeeMembers", JSON.stringify(committeeMembers));
		if (committeeMembersChanges != "") formData.append("committeeMembersChanges", JSON.stringify(committeeMembersChanges));

		if (files.length > 0)
			for (const file of files) {
				formData.append("files", file);
			}

		if (interships.length > 0) formData.append("intership", JSON.stringify(interships));
		if (languages.length > 0) formData.append("languages", JSON.stringify(languages));
		if (profExperiences.length > 0) formData.append("profExp", JSON.stringify(profExperiences));
		if (studies.length > 0) formData.append("studies", JSON.stringify(studies));

		if (intershipsChanges.length > 0) formData.append("intershipChanges", JSON.stringify(intershipsChanges));
		if (languagesChanges.length > 0) formData.append("languagesChanges", JSON.stringify(languagesChanges));
		if (profExperiencesChanges.length > 0) formData.append("profExpChanges", JSON.stringify(profExperiencesChanges));
		if (studiesChanges.length > 0) formData.append("studiesChanges", JSON.stringify(studiesChanges));

		switch (selectedTab) {
			case "Conducerea":
				updateOneBiography(formData, id).then(() => window.location.reload());
				break;
			case "Organigrama":
				updateOneOrganization(formData, id).then(() => window.location.reload());
				break;
			case "Comisiile":
				updateOneSpecialCommittees(formData, id).then(() => window.location.reload());
				break;
			case "Regulamente":
				updateOneRegulations(formData, id).then(() => window.location.reload());
				break;
			case "Sedintele":
				updateOneMeetings(formData, id).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				updateOneVerbalProcedure(formData, id).then(() => window.location.reload());
				break;
			case "Sedinte Comisii de Specialitate":
				updateOneSpecialCommitteesMeets(formData, id).then(() => window.location.reload());
				break;
		}
	};

	const removeItem = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Conducerea":
				removeOneBiography(id).then(() => window.location.reload());
				break;
			case "Organigrama":
				removeOneOrganization(id).then(() => window.location.reload());
				break;
			case "Comisiile":
				removeOneSpecialCommittees(id).then(() => window.location.reload());
				break;
			case "Regulamente":
				removeOneRegulations(id).then(() => window.location.reload());
				break;
			case "Sedintele":
				removeOneMeetings(id).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				removeOneVerbalProcedure(id).then(() => window.location.reload());
				break;
			case "Sedinte Comisii de Specialitate":
				removeOneSpecialCommitteesMeets(id).then(() => window.location.reload());
				break;
			// case "Proiecte de Decizii":
			// 	removeOneDraftDecisions(id).then(() => window.location.reload());
			// 	break;
		}
	};

	const removeItemFile = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Regulamente":
				removeOneRegulationsFile(id).then(() => window.location.reload());
				break;
			case "Sedintele":
				removeOneMeetingsFile(id).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				removeOneVerbalProcedureFile(id).then(() => window.location.reload());
				break;
			case "Sedinte Comisii de Specialitate":
				removeOneSpecialCommitteesMeetsFile(id).then(() => window.location.reload());
				break;
			// case "Proiecte de Decizii":
			// 	removeOneDraftDecisionsFile(id).then(() => window.location.reload());
			// 	break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		setFiles(e.target.files);
	};

	useEffect(() => {
		fetchBiography().then((data) => {
			setBiography(data);
		});
		fetchOneOrganization(1).then((data) => {
			setOrganization(data);
		});
		fetchSpecialCommittees(1).then((data) => {
			setCommittees(data);
		});
		fetchRegulations().then((data) => setRegulations(data));
		fetchMeetings().then((data) => setMeetings(data));
		fetchVerbalProcedure().then((data) => setVerbalProcedure(data));
		fetchSpecialCommitteesMeets()
			.then((data) => setSpecialCommitteesMeets(data))
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div className='d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3'>
				<span>Administrație</span>
				<button
					className='btn btn-primary r-u-corner-radius'
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}>
					<i className='bi bi-plus-circle icon-2' />
				</button>
			</div>
			<div className='d-flex flex-column px-2 pt-3'>
				<Tabs
					defaultActiveKey='Conducerea'
					id='uncontrolled-tab-example'
					className='d-flex justify-content-center mb-3 text-primary fw-bold'
					onSelect={(e) => setSelectedTab(e)}>
					<Tab
						eventKey='Conducerea'
						title='Conducerea'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{biography.rows
									? biography.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															<div className='d-flex flex-column  ps-xl-4'>
																<a
																	role='button'
																	href={BIOGRAPHY_ROUTE}
																	className='text-decoration-none text-primary'>
																	<Card.Title className='py-xl-2'>
																		{item.last_name + " " + item.first_name}
																	</Card.Title>
																</a>
																<div className='d-flex align-items-center justify-content-around'>
																	<span className='border rounded border-primary py-1 px-2 me-xl-2'>
																		{item.role}
																	</span>
																	<span className='border rounded border-primary py-1 px-2 mx-xl-2'>
																		{item.email}
																	</span>
																	<span className='border rounded border-primary py-1 px-2 mx-xl-2'>
																		{item.phone}
																	</span>
																	<span className='border rounded border-primary py-1 px-2 mx-xl-2'>
																		{item.fax}
																	</span>
																	<span className='border rounded border-primary py-1 px-2 mx-xl-2'>
																		{item.birthdate}
																	</span>
																	<span className='border rounded border-primary py-1 px-2 mx-xl-2'>
																		{item.address}
																	</span>
																</div>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Numele</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.last_name}
																value={lastName}
																onChange={(e) => {
																	setLastName(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Prenumele</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.first_name}
																value={firstName}
																onChange={(e) => {
																	setFirstName(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Funcția</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.role}
																value={role}
																onChange={(e) => {
																	setRole(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Data Nasterii</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.birthdate}
																value={birthdate}
																onChange={(e) => {
																	setBirthdate(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Adresa</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.address}
																value={address}
																onChange={(e) => {
																	setAddress(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>E-Mail</span>
															<Form.Control
																aria-label='Phone'
																placeholder={item.email}
																value={email}
																onChange={(e) => {
																	setEmail(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Nr. de Telefon</span>
															<Form.Control
																aria-label='Phone'
																placeholder={item.phone}
																value={phone}
																onChange={(e) => {
																	setPhone(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Nr. de Fax</span>
															<Form.Control
																aria-label='Phone'
																placeholder={item.fax}
																value={fax}
																onChange={(e) => {
																	setFax(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>STUDII</span>
															<Button onClick={addStudies}>Adauga</Button>
															{studies.map((i) => (
																<div
																	className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																	key={i.number}>
																	<Form.Control
																		value={i.title}
																		className='me-xl-5'
																		onChange={(e) => changeStudies("title", e.target.value, i.number)}
																		placeholder={"Anii de Studii"}
																	/>
																	<Form.Control
																		value={i.description}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeStudies("description", e.target.value, i.number)
																		}
																		placeholder={"Studiile Terminate"}
																	/>
																	<Button
																		variant={"outline-danger"}
																		className='my-sm-4'
																		onClick={() => removeStudies(i.number)}>
																		Sterge
																	</Button>
																</div>
															))}
															{item.biography_studies.length > 0
																? item.biography_studies.map((sub_item, sub_idx) => (
																		<div
																			className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																			key={sub_idx}>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.title}
																				onChange={(e) =>
																					changeStudiesChanges(sub_item, "title", e.target.value)
																				}
																			/>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.description}
																				onChange={(e) =>
																					changeStudiesChanges(
																						sub_item,
																						"description",
																						e.target.value
																					)
																				}
																			/>
																			<Button
																				variant={"outline-danger"}
																				onClick={() => removeStudiesChanges(sub_item)}
																				className='my-sm-4'>
																				Sterge
																			</Button>
																		</div>
																  ))
																: null}
															<span className='text-primary  font-2 py-2 ps-1'>EXPERIENTA PROFESIONALA</span>
															<Button onClick={addProfExperiences}>Adauga</Button>
															{profExperiences.map((i) => (
																<div
																	className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																	key={i.number}>
																	<Form.Control
																		value={i.title}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeProfExperiences("title", e.target.value, i.number)
																		}
																		placeholder={"Perioada de Activitate"}
																	/>
																	<Form.Control
																		value={i.description}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeProfExperiences("description", e.target.value, i.number)
																		}
																		placeholder={"Activitatea"}
																	/>
																	<Button
																		variant={"outline-danger"}
																		className='my-sm-4'
																		onClick={() => removeProfExperiences(i.number)}>
																		Sterge
																	</Button>
																</div>
															))}
															{item.biography_prof_experiences.length > 0
																? item.biography_prof_experiences.map((sub_item, sub_idx) => (
																		<div
																			className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																			key={sub_idx}>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.title}
																				onChange={(e) =>
																					changeProfExperiencesChanges(
																						sub_item,
																						"title",
																						e.target.value
																					)
																				}
																			/>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.description}
																				onChange={(e) =>
																					changeProfExperiencesChanges(
																						sub_item,
																						"description",
																						e.target.value
																					)
																				}
																			/>
																			<Button
																				variant={"outline-danger"}
																				className='my-sm-4'
																				onClick={() => removeProfExperiencesChanges(sub_item)}>
																				Sterge
																			</Button>
																		</div>
																  ))
																: null}
															<span className='text-primary  font-2 py-2 ps-1'>LIMBI STRĂINE</span>
															<Button onClick={addLanguages}>Adauga</Button>
															{languages.map((i) => (
																<div
																	className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																	key={i.number}>
																	<Form.Control
																		value={i.title}
																		className='me-xl-5'
																		onChange={(e) => changeLanguages("title", e.target.value, i.number)}
																		placeholder={"Limba"}
																	/>
																	<Form.Control
																		value={i.description}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeLanguages("description", e.target.value, i.number)
																		}
																		placeholder={"Nivel"}
																	/>
																	<Button
																		variant={"outline-danger"}
																		className='my-sm-4'
																		onClick={() => removeLanguages(i.number)}>
																		Sterge
																	</Button>
																</div>
															))}
															{item.biography_languages.length > 0
																? item.biography_languages.map((sub_item, sub_idx) => (
																		<div
																			className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																			key={sub_idx}>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.title}
																				onChange={(e) =>
																					changeLanguagesChanges(
																						sub_item,
																						"title",
																						e.target.value
																					)
																				}
																			/>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.description}
																				onChange={(e) =>
																					changeLanguagesChanges(
																						sub_item,
																						"description",
																						e.target.value
																					)
																				}
																			/>
																			<Button
																				variant={"outline-danger"}
																				className='my-sm-4'
																				onClick={() => removeLanguagesChanges(sub_item)}>
																				Sterge
																			</Button>
																		</div>
																  ))
																: null}
															<span className='text-primary  font-2 py-2 ps-1'>STAGIERI</span>
															<Button onClick={addInterships}>Adauga</Button>
															{interships.map((i) => (
																<div
																	className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																	key={i.number}>
																	<Form.Control
																		value={i.title}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeInterships("title", e.target.value, i.number)
																		}
																		placeholder={"Perioada de Intership"}
																	/>
																	<Form.Control
																		value={i.description}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeInterships("description", e.target.value, i.number)
																		}
																		placeholder={"Activitatea"}
																	/>
																	<Button
																		variant={"outline-danger"}
																		className='my-sm-4'
																		onClick={() => removeInterships(i.number)}>
																		Sterge
																	</Button>
																</div>
															))}
															{item.biography_interships.length > 0
																? item.biography_interships.map((sub_item, sub_idx) => (
																		<div
																			className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																			key={sub_idx}>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.title}
																				onChange={(e) =>
																					changeIntershipsChanges(
																						sub_item,
																						"title",
																						e.target.value
																					)
																				}
																			/>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.description}
																				onChange={(e) =>
																					changeIntershipsChanges(
																						sub_item,
																						"description",
																						e.target.value
																					)
																				}
																			/>
																			<Button
																				variant={"outline-danger"}
																				className='my-sm-4'
																				onClick={() => removeIntershipsChanges(sub_item)}>
																				Sterge
																			</Button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Organigrama'
						title='Organigrama'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{organization ? (
									<div className='shadow-sm border-0 my-2 px-4 rounded'>
										<div className='d-flex flex-column'>
											<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
												<div className='d-flex flex-xl-row flex column align-items-center'>
													{organization.img ? (
														<Image
															className='rounded'
															src={process.env.REACT_APP_API_URL + organization.img}
															style={{ maxHeight: "30vh" }}
														/>
													) : null}
													<div className='d-flex flex-column  ps-xl-4'>
														<a
															role='button'
															href={PRESIDENTS_MESSAGE_ROUTE}
															className='text-decoration-none text-primary'>
															<Card.Title>{organization.title}</Card.Title>
														</a>
														{organization.description ? (
															<div className='truncate'>{parse(organization.description)}</div>
														) : null}
													</div>
												</div>
												<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
													<CustomToggle
														eventKey='organization'
														onClick={() => {
															useAccordionButton("organization");
														}}>
														<i className='bi bi-pencil-square icon-2 pe-2' />
														<span>Edit</span>
													</CustomToggle>
													<button
														className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
														onClick={() => removeItem(organization)}>
														<i className='bi bi-x-circle icon-2 pe-2' />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey='organization'>
											<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
												<div className='d-flex flex-column justify-content-around px-4 py-4'>
													<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
													<Form.Control
														aria-label='Title'
														placeholder={organization.title}
														value={title}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
													<ReactQuill
														theme='snow'
														value={description}
														placeholder={organization.description}
														modules={modules}
														formats={formats}
														onChange={setDescription}
													/>
													<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
													<Form.Control
														type={"file"}
														onChange={selectFile}
														aria-label='PageTitle'
														aria-describedby='basic-addon'
													/>
												</div>
												<button
													className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
													onClick={() => editItem(organization)}>
													<i className='bi bi-check icon-2 pe-2' />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								) : null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Comisiile'
						title='Comisiile'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{committees.count > 0
									? committees.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															<div className='d-flex flex-column  ps-xl-4'>
																<a
																	role='button'
																	href={SPECIALIZED_COMMITTEES_ROUTE}
																	className='text-decoration-none text-primary'>
																	<Card.Title>{item.committeeTitle}</Card.Title>
																</a>
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumirea Comisiei</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.committeeTitle}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Membrii Comisiei</span>
															<Button onClick={addCommitteeMember}>Adauga</Button>
															{committeeMembers.map((i) => (
																<div
																	className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																	key={i.number}>
																	<Form.Control
																		value={i.full_name}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeCommitteeMember("full_name", e.target.value, i.number)
																		}
																		placeholder={"Nume și Prenume"}
																	/>
																	<Form.Control
																		value={i.party_name}
																		className='me-xl-5'
																		onChange={(e) =>
																			changeCommitteeMember("party_name", e.target.value, i.number)
																		}
																		placeholder={"Denumirea partidului"}
																	/>
																	<Button
																		variant={"outline-danger"}
																		className='my-sm-4'
																		onClick={() => removeCommitteeMember(i.number)}>
																		Sterge
																	</Button>
																</div>
															))}
															{item.special_committees_members.length > 0
																? item.special_committees_members.map((sub_item, sub_idx) => (
																		<div
																			className='d-flex flex-xl-row flex-column align-items-center justify-content-around'
																			key={sub_idx}>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.full_name}
																				onChange={(e) =>
																					changeCommitteeMemberChanges(
																						sub_item,
																						"full_name",
																						e.target.value
																					)
																				}
																			/>
																			<Form.Control
																				className='me-xl-5'
																				placeholder={sub_item.party_name}
																				onChange={(e) =>
																					changeCommitteeMemberChanges(
																						sub_item,
																						"party_name",
																						e.target.value
																					)
																				}
																			/>
																			<Button
																				variant={"outline-danger"}
																				className='my-sm-4'
																				onClick={() => removeCommitteeMemberChanges(sub_item)}>
																				Sterge
																			</Button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Regulamente'
						title='Regulamente'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{regulations.count > 0
									? regulations.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															{item.img ? (
																<Image
																	className='rounded'
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className='d-flex flex-column  ps-xl-4'>
																<a
																	role='button'
																	onClick={() => navigate(REGULATIONS_ROUTE + "/" + item.id)}
																	className='text-decoration-none text-primary'>
																	<Card.Title className='truncate'>{item.title}</Card.Title>
																</a>
																{item.description ? (
																	<div className='truncate'>{parse(item.description)}</div>
																) : null}
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.regulations_files.length > 0
																? item.regulations_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{ textAlign: "justify" }}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Sedintele'
						title='Sedintele'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{meetings.count > 0
									? meetings.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															{item.img ? (
																<Image
																	className='rounded'
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className='d-flex flex-column  ps-xl-4'>
																<a
																	role='button'
																	onClick={() => navigate(MEETINGS_ROUTE + "/" + item.id)}
																	className='text-decoration-none text-primary'>
																	<Card.Title className='truncate'>{item.title}</Card.Title>
																</a>
																{item.description ? (
																	<div className='truncate'>{parse(item.description)}</div>
																) : null}
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.meetings_files.length > 0
																? item.meetings_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{ textAlign: "justify" }}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Procese Verbale'
						title='Procese Verbale'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{verbalProcedure.count > 0
									? verbalProcedure.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															{item.img ? (
																<Image
																	className='rounded'
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className='d-flex flex-column  ps-xl-4'>
																<a
																	role='button'
																	onClick={() => navigate(VERBAL_PROCEDURE_ROUTE + "/" + item.id)}
																	className='text-decoration-none text-primary'>
																	<Card.Title className='truncate'>{item.title}</Card.Title>
																</a>
																{item.description ? (
																	<div className='truncate'>{parse(item.description)}</div>
																) : null}
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.verbal_procedure_files.length > 0
																? item.verbal_procedure_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Sedinte Comisii de Specialitate'
						title='Sedinte Comisii de Specialitate'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{specialCommitteesMeets.count > 0
									? specialCommitteesMeets.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															{item.img ? (
																<Image
																	className='rounded'
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className='d-flex flex-column  ps-xl-4'>
																<a
																	role='button'
																	onClick={() => navigate(VERBAL_PROCEDURE_ROUTE + "/" + item.id)}
																	className='text-decoration-none text-primary'>
																	<Card.Title className='truncate'>{item.title}</Card.Title>
																</a>
																{item.description ? (
																	<div className='truncate'>{parse(item.description)}</div>
																) : null}
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.special_committees_meets_files.length > 0
																? item.special_committees_meets_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
				</Tabs>
			</div>
			<AddAdminModal
				show={show}
				onHide={() => setShow(false)}
				selectedTab={selectedTab}
			/>
		</div>
	);
});

export default AdministrationAdmin;
