import React, { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { fetchOneCalendarEvents } from "../http/calendarAPI";
import parse from "html-react-parser";
import ShareButton from "../components/UI/ShareButton";

const EventPage = observer(() => {
	const [event, setEvent] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		fetchOneCalendarEvents(id).then((data) => setEvent(data));
	}, [id]);

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div className='d-flex flex-column col-xl-8 px-xl-4'>
					<div className='py-4 block-title text-center'>
						<span className='text-primary px-2'>{event.title}</span>
					</div>
					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-center'>
						{event.img ? (
							<Image
								style={{ maxHeight: 400, objectFit: "contain", maxHeight: "50vh" }}
								className='rounded'
								src={process.env.REACT_APP_API_URL + event.img}
							/>
						) : null}
						{event.description ? (
							<span className='text-wrap font-1 text-primary ps-xl-3'>{parse(event.description)}</span>
						) : null}
					</div>

					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100'>
						<span className='border border-primary rounded text-primary my-sm-2 p-2 fw-bold'>
							Organizator: {event.organizer}
						</span>
						<span className='border border-primary rounded text-primary p-2 fw-bold'>{event.date + " " + event.time}</span>
						<div className='d-flex flex-row align-items-center justify-content-end ps-xl-1'>
							{/* <i
								className="bi bi-printer text-primary icon-3"
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/> */}
							<ShareButton />
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
});

export default EventPage;
