import { useContext, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Calendar from "../components/Calendar";
import { useNavigate } from "react-router-dom";
import { CITY_NEWS_ROUTE, SUCCESS_STORIES_ROUTE } from "../utils/consts";
import { fetchSuccessStories } from "../http/resourcesServicesAPI";
import { Spinner } from "react-bootstrap";

const SuccessStories = observer(() => {
	const { resourcesServices } = useContext(Context);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchSuccessStories()
			.then((data) => {
				resourcesServices.setSuccessStories(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
			<div className='py-4 px-5 border-bottom col-md-9'>
				<div className='block-title pb-4'>
					<span>Istorii de Succes</span>
				</div>

				<div className='d-flex flex-row flex-wrap align-items-center justify-content-around'>
					{resourcesServices.successStories.count > 0
						? resourcesServices.successStories.rows.map((item, idx) => {
								return (
									<Card
										key={idx}
										style={{ width: "22rem" }}
										className='my-3'>
										<Card.Img
											variant='top'
											src={process.env.REACT_APP_API_URL + item.img}
										/>
										<Card.Body>
											<Card.Title className='mb-3 d-flex align-items-center justify-content-between'>
												<span
													className='border border-primary rounded text-primary p-2'
													style={{ fontSize: "1rem" }}>
													{item.date}
												</span>
											</Card.Title>
											<Card.Text
												role='button'
												className='fw-bold text-primary'
												onClick={() => navigate(SUCCESS_STORIES_ROUTE + "/" + item.id)}>
												{item.title}
											</Card.Text>
										</Card.Body>
									</Card>
								);
						  })
						: null}
				</div>
			</div>
			<Calendar />
		</div>
	);
});

export default SuccessStories;
