import { useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
// import { Table } from "react-bootstrap";
import { Spinner, Image } from "react-bootstrap";
// import { fetchMunicipalEnterprises } from "../http/navLinksAPI";
import ShareButton from "../components/UI/ShareButton";
import logo from "../assets/avrrm_logo.png";

const MunicipalEnterprises = () => {
	// const [enterprises, setEnterprises] = useState();
	// const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	// useEffect(() => {
	// 	fetchMunicipalEnterprises()
	// 		.then((data) => {
	// 			setEnterprises(data);
	// 		})
	// 		.finally(() => setLoading(false));
	// }, []);
	// if (loading) {
	// 	return (
	// 		<div className='d-flex align-items-center justify-content-center py-5'>
	// 			<Spinner animation={"grow"} />
	// 		</div>
	// 	);
	// }

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Obiectivele AVRRM</span>
					</div>
					<Image
						src={logo}
						style={{
							maxHeight: "50vh",
							width: "auto",
							aspectRatio: 1,
							objectFit: "contain",
						}}
					/>
					<div>
						<p>
							<strong>Obiectivele AVRRM sunt:</strong>
						</p>
						<p>
							a) să reprezinte și să apere drepturile și interesele cadrelor militare în rezervă sau în retragere, inclusiv
							cele cu privire la pensiile militarilor, asistenta medicală și protecția socială, accesul la facilitățile de
							recuperare, tratament, odihnă și recreere conform legislației în vigoare, atât în relațiile cu Ministerul
							Apărării, cu autoritățile și instituțiile publice centrale și locale, cât și în fața instanțelor de judecată;
						</p>
						<p>
							b) să contribuie la profesionalizarea ofițerilor, subofițerilor, sergenților și soldaților în rezervă, a
							voluntarilor militari și să ajute la integrarea lor în efortul apărării naționale și colective.
						</p>
						<p>
							c) să contribuie la sprijinirea cadrelor militare în rezervă sau în retragere - pensionari militari, veterani și
							a văduvelor/văduvilor acestora în identificarea unor locuri de muncă corespunzătoare sau în caz contrar, de a-i
							ajuta să se stabilească în localitățile de unde provin;
						</p>
						<p>
							d) să sprijine cadrele militare în rezervă sau în retragere - pensionari militari și văduvele. acestora pentru
							refacerea capacităților fizice și intelectuale și adaptarea la statutul de rezervist/pensionar, promovând astfel
							o îmbătrânire activă, demnă și utilă social, și respectul în societate fată de seniorii militari;
						</p>
						<p>
							e) să consilieze membrii Asociației, la solicitarea lor, în probleme de drepturi și obligații cetățenești; să
							organizeze și desfășoare acțiuni pentru constituirea de fonduri speciale prin donații sau sponsorizări, precum
							și de acțiuni sociale în beneficiul bunăstării cadrelor militare în rezervă sau în retragere, pensionari
							militari, și ale familiilor acestora, în condițiile respectării normelor legale;
						</p>
						<p>
							f) să asigure facilități/condiții membrilor Asociației pentru a menține contactul și relațiile de camaraderie și
							pentru a cultiva sentimentele de corp, inclusiv cu cadrele militare în activitate;
						</p>
						<p>
							g) să respecte și să apere onoarea și demnitatea militară, precum și memoria cadrelor militare care au decedat
							la datorie, în timpul serviciului militar sau după trecerea în rezervă;
						</p>
						<p>
							h) să editeze și publice cărți, periodice sau orice alte publicații pentru promovarea și realizarea scopului și
							obiectivelor Asociației;
						</p>
						<p>
							i) să realizeze/mențină comunicare permanentă cu mass-media, atât pentru promovarea imaginii reale a vieții
							cadrelor militare în rezervă sau în retragere și a Asociației, cât și pentru a răspunde oportun la denigrările
							care apar în societate la adresa acestora;
						</p>
						<p>
							j) să promoveze valorile fundamentale ale democrației, interesele și simbolurile naționale ale Republicii
							Moldova, să cultive valorile civice ale statului de drept, să dezvolte relațiile cu societatea civilă şi să
							sprijine acțiunile și inițiativelor comunităților locale;
						</p>
						<p>
							k) să cultive tradițiile naționale și militare, să promoveze imaginea armatei în societate și dezvoltarea
							științei și artei militare;
						</p>
						<p>
							l) să stimuleze și promoveze interesul publicului pentru îndeplinirea serviciului militar în cadrul Forțelor
							Armate, participarea la educarea tineretului în spiritul dragostei fată de patrie, trecutului glorios al
							poporului și tradițiile armatei sale;
						</p>
						<p>
							m) să promoveze în rândul membrilor săi și în societate respectarea normelor stabilite pentru păstrarea ordinii
							publice, a bunelor moravuri și a normelor de comportament civic;
						</p>
						<p>
							n) să stabilească cooperare/colaborare cu alte asociații similare din Republica Moldova sau din străinătate,
							precum și cu forurile internaționale de profil, în concordanță cu politica de apărare şi relațiile externe ale
							Republicii Moldova și ale armatei sale, pentru îndeplinirea obiectivelor Asociației;
						</p>
						<p>
							o) să promoveze activități pentru conștientizarea de către organele abilitate și opinia publică a problemelor
							cadrelor militare în rezervă sau în retragere, pensionari militari, veterani şi ale familiilor acestora și
							angajarea/inițierea cooperării cu instituțiile publice și alte organizații pentru rezolvarea lor.
						</p>
						<p>
							p) în vederea asigurării forței necesare pentru îndeplinirea obiectivelor sale, AVRRM acționează constant pentru
							coagularea eforturilor tuturor structurilor asociative constituite din cadre militare în rezervă sau în
							retragere, în baza protocoalelor de cooperare ce se vor încheia cu acestea;
						</p>
						<p>
							q) structurile asociative ale cadrelor militare în rezervă și în retragere provenite din Ministerul Apărării
							care au semnat protocoale de cooperare cu AVRRM pentru îndeplinirea unor obiective comune și sunt de acord cu
							statutul și modul de acțiune a acesteia pot deveni, la cerere, structuri afiliate.
						</p>
					</div>
					{/* <Table
						bordered
						hover>
						<thead>
							<tr>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white'>
									#
								</th>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white'>
									Tip de întreprindere
								</th>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white'>
									Denumire
								</th>
							</tr>
						</thead>
						<tbody>
							{enterprises.count > 0
								? enterprises.rows.map((item, idx) => (
										<tr key={idx}>
											<td style={{ verticalAlign: "middle" }}>{idx}</td>
											<td style={{ verticalAlign: "middle" }}>{item.type}</td>
											<td style={{ verticalAlign: "middle" }}>{item.name}</td>
										</tr>
								  ))
								: null}
						</tbody>
					</Table> */}
					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
						<i
							role='button'
							onClick={() => handlePrint()}
							className='bi bi-printer text-primary icon-3 px-4'
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<ShareButton />
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default MunicipalEnterprises;
