import { useEffect, useState, useContext } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton, Tabs, Tab } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { DRAFT_DECISIONS_ROUTE, INITIATION_ROUTE, PUBLIC_CONSULTATIONS_ROUTE, RECOMENDATIONS_ROUTE } from "../../utils/consts";
import {
	fetchConsultations,
	fetchInitiations,
	fetchRecommendations,
	removeOneConsultation,
	removeOneConsultationFile,
	removeOneInitiation,
	removeOneInitiationFile,
	removeOneRecommendations,
	removeOneRecommendationsFile,
	updateOneConsultation,
	updateOneInitiation,
	updateOneRecommendations,
} from "../../http/informationProcessAPI";
import AddDecisionProcessModal from "./Modals/AddDecisionProcessModal";
import { fetchDraftDecisions, removeOneDraftDecisions, removeOneDraftDecisionsFile, updateOneDraftDecisions } from "../../http/navLinksAPI";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";

const DecisionProcessAdmin = observer(() => {
	const { informationProcess } = useContext(Context);
	const [show, setShow] = useState(false);

	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState("Consultare publică");

	const [draftDecisions, setDraftDecisions] = useState([null]);

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className='btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2'
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	const editConsultation = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		for (const file of files) {
			formData.append("files", file);
		}
		updateOneConsultation(formData, id).then(() => window.location.reload());
	};

	const editInitiation = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		for (const file of files) {
			formData.append("files", file);
		}
		updateOneInitiation(formData, id).then(() => window.location.reload());
	};

	const editRecommendations = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		for (const file of files) {
			formData.append("files", file);
		}
		updateOneRecommendations(formData, id).then(() => window.location.reload());
	};

	const editDraftDecision = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		for (const file of files) {
			formData.append("files", file);
		}
		updateOneDraftDecisions(formData, id).then(() => window.location.reload());
	};

	const removeDraftDecision = (item) => {
		removeOneDraftDecisions(item.id).then(() => window.location.reload());
	};

	const removeDraftDecisionFile = (item) => {
		removeOneDraftDecisionsFile(item.id).then(() => window.location.reload());
	};

	const removeConsultation = (item) => {
		removeOneConsultation(item.id).then(() => window.location.reload());
	};

	const removeConsultationFile = (item) => {
		removeOneConsultationFile(item.id).then(() => window.location.reload());
	};

	const removeInitiation = (item) => {
		removeOneInitiation(item.id).then(() => window.location.reload());
	};
	const removeInitiationFile = (item) => {
		removeOneInitiationFile(item.id).then(() => window.location.reload());
	};

	const removeRecommendations = (item) => {
		removeOneRecommendations(item.id).then(() => window.location.reload());
	};
	const removeRecommendationsFile = (item) => {
		removeOneRecommendationsFile(item.id).then(() => window.location.reload());
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		setFiles(e.target.files);
	};

	useEffect(() => {
		fetchConsultations().then((data) => {
			informationProcess.setConsultation(data);
		});
		fetchInitiations().then((data) => {
			informationProcess.setInitiation(data);
		});
		fetchRecommendations().then((data) => {
			informationProcess.setRecommendations(data);
		});
		fetchDraftDecisions()
			.then((data) => setDraftDecisions(data))
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div className='d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3'>
				<span>Procesul decizional</span>
				<button
					className='btn btn-primary r-u-corner-radius'
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}>
					<i className='bi bi-plus-circle icon-2' />
				</button>
			</div>
			<div className='d-flex flex-column px-2 pt-3'>
				<Tabs
					defaultActiveKey='Consultare publică'
					id='uncontrolled-tab-example'
					className='d-flex justify-content-center mb-3 text-primary fw-bold'
					onSelect={(e) => setSelectedTab(e)}>
					<Tab
						eventKey='Consultare publică'
						title='Consultare publică'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{informationProcess.consultation.count > 0
									? informationProcess.consultation.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div>
															<a
																href={PUBLIC_CONSULTATIONS_ROUTE + "/" + item.id}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeConsultation(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.public_consultations_files.length > 0
																? item.public_consultations_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeConsultationFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editConsultation(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Inițiere proiect'
						title='Inițiere proiect'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{informationProcess.initiation.count > 0
									? informationProcess.initiation.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div>
															<a
																href={INITIATION_ROUTE + "/" + item.id}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeInitiation(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.initiation_files.length > 0
																? item.initiation_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{ textAlign: "justify" }}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeInitiationFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editInitiation(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Proiecte de Decizii'
						title='Proiecte de Decizii'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{draftDecisions && draftDecisions.count > 0
									? draftDecisions.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div className='d-flex flex-xl-row flex column align-items-center'>
															{item.img ? (
																<Image
																	className='rounded'
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className='d-flex flex-column  ps-xl-4'>
																<Link
																	role='button'
																	to={DRAFT_DECISIONS_ROUTE + "/" + item.id}
																	className='text-decoration-none text-primary'>
																	<Card.Title className='truncate'>{item.title}</Card.Title>
																</Link>
																{item.description ? (
																	<div className='truncate'>{parse(item.description)}</div>
																) : null}
															</div>
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeDraftDecision(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.draft_decisions_files.length > 0
																? item.draft_decisions_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeDraftDecisionFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editDraftDecision(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Sinteza recomandărilor'
						title='Sinteza recomandărilor'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{informationProcess.recommendations.count > 0
									? informationProcess.recommendations.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
														<div>
															<a
																href={RECOMENDATIONS_ROUTE + "/" + item.id}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeRecommendations(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																value={description}
																placeholder={item.description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.recommendations_files.length > 0
																? item.recommendations_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a href={process.env.REACT_APP_API_URL + sub_item.doc}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeRecommendationsFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editRecommendations(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
				</Tabs>
			</div>
			<AddDecisionProcessModal
				show={show}
				onHide={() => setShow(false)}
				selectedTab={selectedTab}
			/>
		</div>
	);
});

export default DecisionProcessAdmin;
